<template>
    <comp-form ref="comp_form" :structure="structure" :uuid="$route.query.id" submit-api="WEB://Driver/Save" detail-api="WEB://Driver/FindByUuid">
        <template #operate>
            <button @click="onCardReader">读取身份证</button>
        </template>
    </comp-form>
</template>

<script>
import CompForm from "@/components/comp-form.vue"

import CardReader from "@/module/utils/card_reader"
import FileUtil from "@/module/file/file_util"
import Utils from "@/module/utils/utils"

export default {
    components: {
        CompForm,
    },

    data() {
        return {
            structure: [
                {
                    type: "ORDINARY",
                    name: "common",
                    data: [
                        {
                            field: "username",
                            name: "username",
                            title: "姓名",
                            type: "Input",
                            required: true,
                        },
                        {
                            field: "phone",
                            name: "phone",
                            title: "手机号码",
                            required: true,
                            type: "Input",
                        },
                        {
                            field: "idNumber",
                            name: "idNumber",
                            title: "身份证号",
                            required: true,
                            type: "Input",
                        },
                        {
                            field: "address",
                            name: "address",
                            title: "地址",
                            type: "Input",
                            required: false,
                        },
                        {
                            field: "enable",
                            name: "enable",
                            title: "是否启用",
                            type: "Switch",
                        },
                    ],
                    title: "通用数据",
                    list: false,
                    exist: true,
                },
                {
                    type: "ORDINARY",
                    name: "resource",
                    data: [
                        {
                            field: "record",
                            name: "record",
                            title: "电子档案",
                            type: "Upload",
                            required: false,
                            uploadConfig: {
                                type: "file",
                                belong: "driver-record",
                            },
                        },
                        {
                            field: "idFront",
                            name: "idFront",
                            title: "身份证正面",
                            type: "Upload",
                            required: false,
                            uploadConfig: {
                                type: "image",
                            },
                        },
                        {
                            field: "idBack",
                            name: "idBack",
                            title: "身份证反面",
                            type: "Upload",
                            required: false,
                            uploadConfig: {
                                type: "image",
                            },
                        },
                        {
                            field: "photo",
                            name: "photo",
                            title: "电子照片",
                            type: "Upload",
                            required: false,
                            uploadConfig: {
                                type: "image",
                            },
                        },
                    ],
                    title: "资源文件",
                    list: false,
                    exist: true,
                },
                {
                    type: "PASSWORD",
                    name: "password",
                    data: [
                        {
                            field: "password",
                            name: "password",
                            title: "密码",
                            type: "Password",
                            required: false,
                        },
                    ],
                    title: "设置密码",
                    list: false,
                    exist: true,
                },
            ],
        }
    },

    methods: {
        onCardReader() {
            CardReader.read().then(res => {
                console.log(res)

                // 头像图片 base64 文件
                const avatar_src = "data:image/jpeg;base64," + res.identityPic

                this.$refs.comp_form.setFormData({
                    username: res.partyName,
                    idNumber: res.certNumber,
                    photo: {
                        id: Utils.getUuid(),
                        src: avatar_src,
                        name: res.partyName + ".jpg",
                        blob: FileUtil.dataURLtoBlob(avatar_src),
                    },
                })
            })
        },
    },
}
</script>
