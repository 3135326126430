import Message from "@/module/interactive/message"

export default class CardReader {
    private static ajax(options: obj) {
        if (options.type == null) {
            options.type = "POST"
        }

        if (options.url == null) {
            options.url = ""
        }

        if (options.timeout == null) {
            options.timeout = 5000
        }

        if (options.onComplate == null) {
            options.onComplate = function () {}
        }

        if (options.onError == null) {
            options.onError = function () {}
        }

        if (options.onSuccess == null) {
            options.onSuccess = function () {}
        }

        if (options.data) {
            options.data = ""
        }

        var xml
        if (typeof ActiveXObject != "undefined") {
            var aVersions = ["Microsoft.XMLHTTP", "Msxml2.XMLHttp.6.0", "Msxml2.XMLHttp.5.0", "Msxml2.XMLHttp.4.0", "Msxml2.XMLHttp.3.0"]
            for (var i = 0; i < aVersions.length; i++) {
                try {
                    xml = new ActiveXObject(aVersions[i])
                } catch (e) {
                    console.error(e)
                }
            }
        } else if (typeof XMLHttpRequest != "undefined") {
            xml = new XMLHttpRequest()
        }

        xml.open(options.type, options.url, true)

        var timeoutLength = options.timeout

        var requestDone = false

        setTimeout(function () {
            requestDone = true
        }, timeoutLength)

        xml.onreadystatechange = function () {
            if (xml.readyState == 4 && !requestDone) {
                if (httpSuccess(xml)) {
                    options.onSuccess(httpData(xml))
                } else {
                    options.onError()
                }

                options.onComplate()

                xml = null
            }
        }

        xml.send()

        function httpSuccess(r) {
            try {
                return (
                    (!r.status && location.protocol == "file:") ||
                    (r.status >= 200 && r.status <= 300) ||
                    r.status == 304 ||
                    (navigator.userAgent.indexOf("Safari") >= 0 && typeof r.status == "undefined")
                )
            } catch (e) {
                console.error(e)
            }
            return false
        }

        function httpData(r) {
            var ct = r.getResponseHeader("responseType")
            if (ct) {
                if (ct == "xml") {
                    return r.responseXML
                }
                if (ct == "json") {
                    return JSON.parse(r.responseText)
                }
            }
            return r.responseText
        }
    }

    private static isOpen = false

    public static connect(): Promise<void> {
        return new Promise((resolve, reject) => {
            var options: obj = new Object()
            options.type = "GET"
            //options.url = "/OpenDevice";
            options.url = "http://127.0.0.1:19196/OpenDevice" + "&" + "t=" + Math.random() //URL后面加一个随机参数的目的是为了防止IE内核浏览器的数据缓存

            options.timeout = 5000

            options.onSuccess = () => {
                this.isOpen = true

                resolve()
            }

            options.onError = () => {
                Message.error("取卡设备连接失败，请检查设备连接！")

                reject()
            }

            this.ajax(options)
        })
    }

    public static close() {
        var options: obj = new Object()
        options.type = "GET"
        //options.url="CloseDevice";
        options.url = "http://127.0.0.1:19196/CloseDevice" + "&" + "t=" + Math.random() //URL后面加一个随机参数的目的是为了防止IE内核浏览器的数据缓存
        options.timeout = 5000

        options.onSuccess = () => {
            this.isOpen = false
        }

        this.ajax(options)
    }

    public static read(): Promise<obj> {
        return new Promise((resolve, reject) => {
            if (!this.isOpen) {
                this.connect().then(() => {
                    this.readcard(resolve, reject)
                })

                return
            }

            this.readcard(resolve, reject)
        })
    }

    private static readcard(resolve, reject) {
        Message.info("读卡中...")

        var options: obj = new Object()
        options.type = "GET"
        //options.url="readcard";
        options.url = "http://127.0.0.1:19196/readcard" + "&" + "t=" + Math.random() //URL后面加一个随机参数的目的是为了防止IE内核浏览器的数据缓存
        options.timeout = 5000
        options.onSuccess = res => {
            var result: obj

            if (typeof res === "string") {
                result = JSON.parse(res)
            } else {
                result = res
            }

            if (result.resultFlag != 0) {
                Message.error("读卡失败，错误信息：" + res.errorMsg)

                return reject()
            }

            Message.success("读卡成功")

            resolve(result)
        }

        options.onError = () => {
            Message.error("读卡失败")

            reject()
        }

        this.ajax(options)
    }
}
